
import './styles/projectModal.css'

const template = require('./json/projects.json')['eth']
export default function EthProjects() {

    const projects = Object.entries(template).map((project: any, key: number) => {
        const name = project[0]
        const url = project[1]

        return (
            <div className="project-container">
                <div key={key} className="d-flex justify-content-center">
                    <a id="logo-container" href={url} target="_blank" rel="noreferrer">
                        <img
                            src={require(`../assets/pic/ethProjects/${name}.svg`)}
                            alt={`${name} Project`}
                            className="img-fluid project-logo"
                        />
                        <br />
                        <span className="project-name">{name}</span>
                    </a>
                </div>
            </div>
        )
    })

    return (
        <div className="projects-container ethereum">
            <p className="chain">Ethereum</p>
            <div className="d-flex justify-content-center projects">
                {projects}
            </div>
        </div>
    )
}