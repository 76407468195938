import React, { useEffect, useState } from "react";
import "@lottiefiles/lottie-player";
// eslint-disable-next-line
import {
    BrowserRouter,
    useNavigate,
    Routes,
    Route,
    Link as NavLink,
} from "react-router-dom";
import banner from "../assets/pic/banner.svg";
import Footer from "./Footer";

// import Page from './Page'
import Particle from "./Particles";
import ApplySection from "./ApplySection";
import introAnimation from "./json/introAnimation.json";
import Sidebar from "./Sidebar";
import Home from "./Home";
import Detail from "./Detail";
import Service from "./Service";
import Timeline from "./Timeline";

import "./styles/projectModal.css";
import EthProjects from "./EthProjects";

const template = require("./json/mintnity.json");

export default function Index() {
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(0);
    const [menuToggle, setMenuToggle] = useState(true);
    const navigate = useNavigate();

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        resizeWindow();

        // window.addEventListener("popstate", handleBackPage);
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
            // window.removeEventListener("popstate", handleBackPage);
        };
    });

    function handleNavigate() {
        window.scroll({
            top: 0,
            behavior: "auto",
        });
        navigate("/");
    }

    function navigateApply() {
        window.scroll({
            top: 0,
            behavior: "auto",
        });
        navigate("/apply");
    }

    const navItems = template["nav"].map((item: string, key: number) => {
        const pathname = window.location.pathname;
        const path = pathname !== "/apply" ? item : "home";
        if (windowWidth > 992) {
            return (
                <a
                    key={key}
                    className="item mx-2"
                    href={`#${path}`}
                    onClick={() => {
                        handleNavigate();
                    }}
                >
                    <li className="nav-item item item-text" key={key}>
                        <span className="nav-link item-text">{item}</span>
                    </li>
                </a>
            );
        }
        return (
            <a
                key={key}
                className="item mx-2"
                href={`#${path}`}
                onClick={() => {
                    setMenuToggle(!menuToggle);
                    handleNavigate();
                }}
            >
                <li className="nav-item item-text" key={key}>
                    <span
                        className="nav-link item-text"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarContent"
                    >
                        {item}
                    </span>
                </li>
            </a>
        );
    });

    return (
        <div>
            {loading ? (
                <div
                    style={{ height: "100vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <lottie-player
                        src={JSON.stringify(introAnimation)}
                        background="transparent"
                        speed="1.3"
                        style={{ width: "300px" }}
                        autoplay
                    ></lottie-player>
                </div>
            ) : (
                <div>
                    {/* <Sidebar /> */}

                    <nav className="navbar sticky-top navbar-expand-lg p-0 navbar-custom">
                        <div className="container-fluid p-0">
                            <div
                                className="container-fluid d-flex justify-content-between pt-2"
                                style={{ marginBottom: "0.4rem" }}
                            >
                                <a
                                    className="navbar-brand navbar-icon"
                                    href="https://www.mintnity.com"
                                >
                                    <img
                                        src={banner}
                                        className="img-responsive nav-logo"
                                        alt="Logo"
                                    />
                                </a>
                                <button
                                    id="menu-button"
                                    className="navbar-toggler menu-button"
                                    type="button"
                                    onClick={() => setMenuToggle(!menuToggle)}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarContent"
                                    aria-controls="navbarContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    {menuToggle ? (
                                        <i
                                            className="bi bi-list"
                                            style={{ color: "white" }}
                                        ></i>
                                    ) : (
                                        <i
                                            className="bi bi-x-lg"
                                            style={{ color: "white" }}
                                        ></i>
                                    )}
                                </button>
                            </div>
                            <div
                                className="collapse navbar-collapse navbar-right mr-lg-5"
                                id="navbarContent"
                            >
                                <div className="container">
                                    <ul className="navbar-nav mb-3 mb-lg-0 container p-0 d-flex justify-content-center">
                                        {navItems}
                                        {
                                            windowWidth > 992 ? (
                                                <div className="d-flex">
                                                    {/* <li className="nav-item item item-text mx-2">
                                                    <span className="nav-link item-text" data-bs-toggle="modal" data-bs-target="#allProjects">
                                                        Projects
                                                    </span>
                                                </li> */}
                                                    <li
                                                        className="nav-item item item-text mx-2"
                                                        onClick={() => {
                                                            navigateApply();
                                                        }}
                                                    >
                                                        <span className="nav-link item-text">
                                                            Apply
                                                        </span>
                                                    </li>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                            // <li className="nav-item item item-text">
                                            //     <span className="nav-link item-text" data-bs-toggle="modal" data-bs-target="#allProjects">
                                            //         Projects
                                            //     </span>
                                            // </li>
                                        }
                                        {windowWidth > 992 ? (
                                            <span></span>
                                        ) : (
                                            <li
                                                className="nav-item item item-text"
                                                onClick={() => {
                                                    setMenuToggle(!menuToggle);
                                                    navigateApply();
                                                }}
                                                data-bs-toggle="collapse"
                                                data-bs-target="#navbarContent"
                                            >
                                                <span className="nav-link item-text">
                                                    Apply
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div
                        className="modal fade"
                        id="allProjects"
                        tabIndex={-1}
                        aria-labelledby="allProjectLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content modal-container">
                                <p className="project-title">
                                    Projects Launched
                                </p>
                                <div className="modal-body modal-container2">
                                    <EthProjects />
                                </div>
                                <div className="modal-footer d-flex justify-content-between">
                                    <p className="footer-text">
                                        *Unreleased projects are not listed
                                        above.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-secondary footer-btn"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Routes>
                        <Route path="/apply" element={<ApplySection />} />
                        <Route
                            path="/*"
                            element={
                                <div>
                                    <section id="Home">
                                        <Home />
                                    </section>
                                    <section id="Detail">
                                        <Detail />
                                    </section>
                                    <section id="Service">
                                        <Service />
                                    </section>
                                    <section id="Timeline">
                                        <Timeline />
                                    </section>
                                </div>
                            }
                        />
                    </Routes>
                    <Footer />
                </div>
            )}
            <Particle />
        </div>
    );
}
