import React, { useEffect, useState } from 'react'
import './styles/applySection.css'

import firebase from 'firebase/compat/app';
import { getDatabase, ref, push } from 'firebase/database'
import { useNavigate } from "react-router-dom";

import applyAnimation from './json/applyAnimation.json'
import submitAnimation from './json/submitAnimation.json'
const template = require('./json/applySection.json')


const firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_STORAGE_BUCKET,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.APP_ID
};

let Firebase = firebase.initializeApp(firebaseConfig);
const db = getDatabase(Firebase);

export default function ApplySection() {
    const navigate = useNavigate()

    const [name, setName] = useState('')
    // const [blockchain, setBlockchain] = useState('')
    const [project, setProject] = useState('')
    const [mintPrice, setMintPrice] = useState('')
    const [launchDate, setLaunchDate] = useState('')
    const [far, setFar] = useState('')
    const [service, setService] = useState<string[]>([''])
    const [serviceOther, setServiceOther] = useState('')
    const [socialmedia, setSocialMedia] = useState('')
    const [website, setWebsite] = useState('')
    const [budget, setBudget] = useState('')
    const [reach, setReach] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [message, setMessage] = useState('')

    const [submit, setSubmit] = useState(false)

    const [loading, setLoading] = useState(true)
    const [submitting, setsubmitting] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    })

    function handleSubmit() {
        if (name !== '' && project !== '' && mintPrice !== '' && launchDate !== '' && far !== '' && budget !== '' && reach !== '') {
            setSubmit(true)
            const timestamp = new Date().toLocaleString()
            console.log(timestamp)
            let body = {
                "A Time": timestamp,
                // "B Blockchain": blockchain,
                "C Name": name,
                "D Project": project,
                "E How far": far,
                "F Service needed": [...service, serviceOther],
                "G Budget": budget,
                "H Mint Price": mintPrice,
                "I Launch Date": launchDate,
                "J Website Link": website,
                "K Social media link": socialmedia,
                "L PromoCode": promoCode,
                "M Message": message,
                "N Reach method": reach
            }
            push(ref(db), body)
                .then((res) => {
                    // console.log("animation")
                    setsubmitting(false)
                    window.scroll({
                        top: 0,
                        behavior: 'auto'
                    })
                    setTimeout(() => {
                        console.log(res);
                        setSubmit(false)
                        // console.log("NAVIGATE TO HOME")
                        navigate('/', { replace: true })
                    }, 3000)
                })
                .catch((err) => {
                    // console.log(err)
                    alert("Error in sending your application! Please consider reaching Mintnity frontline through Whatsapp!")
                })
        } else {
            alert("Please answer all the question with asterisk!")
        }
    }

    function handleService(value: string) {
        const index = service.indexOf(value)
        if (index > -1) {
            const temp = service.filter(item => item !== service[index])
            // console.log(temp)
            setService(temp)
            return
        }
        setService([...service, value])
    }

    // const blockchainOptions = template['blockchainOptions'].map((key: string, index: number) => {
    //     return (
    //         <div key={index} className="form-check form-check-inline" >
    //             <input className="form-check-input" type="radio" name="inlineRadioOptions2" id={`blockchain${index}`} value={`blockchainOption${index}`} onClick={() => { setBlockchain(key) }} />
    //             <label className="check-field form-check-label" htmlFor={`blockchain${index}`}>{key}</label>
    //         </div>
    //     )
    // })

    const farOptions = template['farOptions'].map((key: string, index: number) => {
        return (
            <div key={index} className="form-check form-check-inline" >
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id={`far${index}`} value={`farOption${index}`} onClick={() => { setFar(key) }} />
                <label className="check-field form-check-label" htmlFor={`far${index}`}>{key}</label>
            </div>
        )
    })

    const confirmServices = [...service, serviceOther].map((item, key) => {
        if (key === 0) return <span></span>
        if (item === "Other") {
            if (key === 1) {
                return (
                    <span key={key}>{item}: </span>
                )
            } else {
                return (
                    <span key={key}>, {item}: </span>
                )
            }
        }
        if (service.indexOf(item) < 0 && service.indexOf("Other") > -1) {
            return (
                <span key={key}>{item}</span>
            )
        }
        if (service.indexOf(item) > -1) {
            if (key === 1) {
                return (<span key={key}>{item}</span>)
            } else {
                return (<span key={key}>, {item}</span>)
            }
        }
        return (
            <span></span>
        )
    })

    const serviceOptions = template['serviceOptions'].map((key: any, index: number) => {
        return (
            <div key={index} className="form-check form-check-inline" >
                <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id={`service${index}`} value={`serviceOption${index}`} onClick={() => { handleService(key) }} />
                <label className="check-field form-check-label" htmlFor={`service${index}`}>{key}</label>
            </div>
        )
    })
    return (
        <div>
            {loading
                ? <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">
                    <lottie-player src={JSON.stringify(applyAnimation)} background="transparent" speed="2.5" style={{ width: '300px' }} autoplay />
                </div>

                : <div>
                    {submitting
                        ? <div className="d-flex justify-content-center align-items-center">
                            <div className="form-container container-xs my-5 py-3 px-3 px-md-5 rounded">
                                <div className="heading-container container rounded mt-5 py-3" style={{ height: '10vh' }}>
                                    <p className="text-center apply-title">Mintnity Service Application</p>
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">What is your name?*</p>
                                    <input id="input-field" placeholder="Input name" className="response form-control" type="text" onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">What is your project called?*</p>
                                    <input id="input-field" placeholder="Input project name" className="response form-control" type="text" onChange={(e) => setProject(e.target.value)} />
                                </div>
                                {/* <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">Which blockchain will your project be on?*</p>
                                    {blockchainOptions}
                                </div> */}
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">How much is the mint price?*</p>
                                    <input id="input-field" placeholder="Input mint price" className="response form-control" type="text" onChange={(e) => setMintPrice(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">When will be the target launch date?*</p>
                                    <input id="input-field" placeholder="Input date" className="response form-control" type="text" onChange={(e) => setLaunchDate(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">How far are you?*</p>
                                    {farOptions}
                                </div>

                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">What services do you want us to provide?*</p>
                                    {serviceOptions}
                                    <div className=" form-check form-check-inline" >
                                        <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="serviceOther" value="option1" onClick={(e) => { handleService('Other') }} />
                                        <div className="d-flex">
                                            <label className="check-field form-check-label" htmlFor="serviceOther">Other</label>
                                            <input style={{ display: 'inline-block', marginLeft: '0.25rem', paddingTop: 0, paddingBottom: 0 }} id="input-field" placeholder="Please specify" className="response form-control" type="text" onChange={(e) => setServiceOther(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">Have you set up your marketing social media like Discord and instagram? If yes you could share it here.</p>
                                    <input id="input-field" placeholder="Input the corresponding link" className="response form-control" type="text" onChange={(e) => setSocialMedia(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">Have you set up your website? If yes you could share it here.</p>
                                    <input id="input-field" placeholder="Input website link" className="response form-control" type="text" onChange={(e) => setWebsite(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">What is your budget in HKD?*</p>
                                    <input id="input-field" placeholder="Input budget" className="response form-control" type="text" onChange={(e) => setBudget(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">How can we reach you? You can share your email/discord name here.*</p>
                                    <input id="input-field" placeholder="Input contact information" className="response form-control" type="text" onChange={(e) => setReach(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">Anything you want to tell us</p>
                                    <textarea id="input-field-textarea" placeholder="Anything" className="response-textarea form-control" onChange={(e) => setMessage(e.target.value)} />
                                </div>
                                <div className="question-container container rounded mt-4 px-5 py-4" >
                                    <p className="question">Do you have any promotion code?</p>
                                    <input id="input-field" placeholder="Input promotion code" className="response form-control" type="text" onChange={(e) => setPromoCode(e.target.value)} />
                                </div>
                                {/* <div className="container d-flex justify-content-end align-items-center pt-4">
                                    <input className="submitButton btn-primary" type="submit" disabled={submit} onClick={() => handleSubmit()} />
                                </div> */}


                                <div className="container d-flex justify-content-end align-items-center pt-4">
                                    <button id="aa" type="submit" className="submitButton btn-primary" data-bs-toggle="modal" data-bs-target="#modal">
                                        Confirm to apply
                                    </button>
                                </div>

                                <div className="modal fade " id="modal" role="dialog" aria-labelledby="moalTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered " role="document">
                                        <div className="modal-content response-modal-container">
                                            <div className="modal-header">
                                                <p className="modal-title response-modal-title" id="Title">Please confirm your response:</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Name:
                                                    </p>
                                                    <p className="confirm-value">{name}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Project Name:
                                                    </p>
                                                    <p className="confirm-value">{project}</p>
                                                </div>
                                                {/* <div className="response-container">
                                                    <p className="confirm-text">
                                                        Blockchain:
                                                    </p>
                                                    <p className="confirm-value">{blockchain}</p>
                                                </div> */}
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Mint price:
                                                    </p>
                                                    <p className="confirm-value">{mintPrice}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Target launch Date:
                                                    </p>
                                                    <p className="confirm-value">{launchDate}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Current progress:
                                                    </p>
                                                    <p className="confirm-value">{far}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Services required:
                                                    </p>
                                                    <p className="confirm-value">{confirmServices}</p>
                                                
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Social media link:
                                                    </p>
                                                    <p className="confirm-value">{socialmedia}</p>

                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Website:
                                                    </p>
                                                    <p className="confirm-value">{website}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Budget:
                                                    </p>
                                                    <p className="confirm-value">{budget}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Contact information:
                                                    </p>
                                                    <p className="confirm-value">{reach}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Message to Mintnity:
                                                    </p>
                                                    <p className="confirm-value">{message}</p>
                                                </div>
                                                <div className="response-container">
                                                    <p className="confirm-text">
                                                        Promotion code:
                                                    </p>
                                                    <p className="confirm-value">{promoCode}</p>
                                                </div>

                                            </div>
                                            <div className="modal-footer d-flex justify-content-between">
                                                <button type="button" className="btn btn-secondary footer-btn" data-bs-dismiss="modal">Back to edit</button>
                                                <button type="button" className="btn btn-primary footer-btn" data-bs-dismiss="modal" onClick={() => handleSubmit()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">
                            <lottie-player src={JSON.stringify(submitAnimation)} background="transparent" speed="2" style={{ width: '300px' }} autoplay />
                        </div>
                    }
                </div>
            }
        </div>



    )
}