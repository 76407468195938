
import './styles/service.css'
import gasBlue from '../assets/pic/gasBlue.png'
import gasRed from '../assets/pic/gasRed.png'
import ES from '../assets/pic/eth.svg'
import ESAnimation from '../assets/pic/EthAnimation.gif'
import s1 from '../assets/pic/1.svg'
import s3 from '../assets/pic/3.svg'
import s4 from '../assets/pic/4.svg'
import s5 from '../assets/pic/5.svg'
import s6 from '../assets/pic/6.svg'
import s7 from '../assets/pic/7.svg'
import s8 from '../assets/pic/8.svg'


import Fade from 'react-reveal/Fade';


export default function Service() {
    const mouseOverHandler = (event: any) => {
        const img = event.currentTarget
        img.src = ESAnimation
    }

    const mouseOutHandler = (event: any) => {
        const img = event.currentTarget
        img.src = ES
    }

    return (
        <div className="container-fluid service-container d-flex align-items-center">
            <div className="container service-container2 d-flex align-items-center">
                <div className="row justify-content-around">
                    <div className="col-lg-5 service-intro">
                        <Fade bottom>
                            <div className="d-flex justify-content-center">
                                <div className="service-banner-container">
                                    <p className="service-banner">All-in-one package <br /> NFT Service</p>
                                </div>
                            </div>
                        </Fade>


                        <Fade bottom>
                            <div className="d-flex justify-content-center" >
                                <img
                                    src={ES}
                                    alt="service demo"
                                    className=" gif"
                                onMouseOver={mouseOverHandler}
                                    onMouseOut={mouseOutHandler}
                                    style={{background: '#170434'}}
                                />
                            </div>
                        </Fade>

                    </div>
                    <div className="col-lg-6 d-flex align-items-center pt-5 pt-lg-0 mb-5 mb-lg-0">
                        <div className="px-5 px-lg-0 mb-5">
                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s8} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Website delivery in 6 days
                                    </p>
                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s4} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Website free adjustment
                                    </p>
                                </div>
                            </Fade>

                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s3} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Installment plan
                                    </p>
                                </div>
                            </Fade>

                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s5} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Minting countdown
                                    </p>
                                </div>
                            </Fade>

                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s6} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Blind box minting
                                    </p>
                                </div>
                            </Fade>

                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s7} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        24/7 technical support
                                    </p>
                                </div>
                            </Fade>

                            <Fade bottom>
                                <div className="d-flex align-items-center service-content-container">
                                    <img src={s1} alt="" className="service-content-icon" />
                                    <p className="text-start service-content">
                                        Advanced blockchain services (DAO, stacking, breeding etc.)
                                    </p>
                                </div>
                            </Fade>


                        </div>
                    </div>
                </div>
            </div>

            <img className="gas-blue" src={gasBlue} alt="" />
            <img className="gas-red" src={gasRed} alt="" />
        </div>
    )
}