import React from 'react'
import Slide from 'react-reveal/Slide'

import './styles/index.css'
import './styles/detail.css'

const template = require('./json/detail.json')

export default function Detail() {
    
    const detailInfo = template['detail'].map((key: any, index: number) => {
        return (
            <div className="col-lg-6 detail-container" key={index}>
                <Slide right>
                <div>
                    <div className="d-flex justify-content-center">
                        <img
                            src={require(`../assets/pic/detail${index}.svg`)}
                            alt={key['header']}
                            className="detail-image"
                        />
                    </div>
                    <p className="detail-header">{key['header']}</p>
                    <p className="detail-content" style={{ whiteSpace: 'pre-line' }}>
                        {key['content']}
                    </p>                    
                </div>
                </Slide>
            </div>
        )
    })


    return (
        <div className="container-fluid d-flex justify-content-center align-items-center detail" >
            <div>
            <div className="container px-5">
                <p className="detail-heading">Why Mintnity?</p>
                <p className="detail-description">Built for Ethereum & Sol NFTs</p>
            </div>
            <div className="container px-5 py-5" style={{overflow: 'hidden'}}>
                <div className="row justify-content-between" >
                    {detailInfo}
                </div>
            </div>
            </div>
            
        </div>
    )
}