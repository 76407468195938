

import { useNavigate } from "react-router-dom";

import './styles/applyButton.css'
import applyButtonAnimation from './json/applyButtonAnimation.json'

export default function ApplyButton() {
    const navigate = useNavigate()
    function handleApply() {
        console.log("NAVIGATE TO APPLY")
        window.scroll({
            top: 0,
            behavior: 'auto'
        })
        navigate('/apply')
    }

    return (
        <div className=" d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center point-left">
                <lottie-player src={JSON.stringify(applyButtonAnimation)} background="transparent" speed="1.3" style={{ width: '50px' }} loop autoplay></lottie-player>
            </div>
            <button className="btn button-transparent" onClick={() => { handleApply() }}>
                <p className="button-text">Apply Now</p>
            </button>
            <div className="d-flex justify-content-center align-items-center point-right">
                <lottie-player src={JSON.stringify(applyButtonAnimation)} background="transparent" speed="1.3" style={{ width: '50px' }} loop autoplay></lottie-player>
            </div>
        </div>
    )
}