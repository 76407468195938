


import ApplyButton from './ApplyButton'
import './styles/timeline.css'

import Fade from 'react-reveal/Fade';


export default function Timeline() {
    return (
        <div className="container-fluid timeline-container">
            <div className="timeline-container2">
                <div className="row justify-content-between">
                    <div className="col-lg-5 d-flex align-content-around flex-wrap">
                        <div className="title-container">
                            <Fade left>
                                <p className="timeline-title p-2">
                                    Launching timeline
                                </p>
                            </Fade>
                            <Fade left>
                                <p className="timeline-description p-2">
                                    Mintnity promises to deliver our services on time as we have engaged in so many projects before and familiar with each stage of a NFT project
                                </p>
                            </Fade>
                        </div>
                        <div className="d-none d-lg-block w-100">
                            <div className="d-flex justify-content-center">
                                <Fade left>
                                    <ApplyButton />
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="timeline d-flex align-items-center justify-content-center">
                            <div >
                                <div className="d-flex align-items-center roadmap-container container1 right">
                                    <div className="container11">
                                        <Fade right>
                                            <div className="roadmap-content content-right">
                                                <p className="roadmap-title">
                                                    Meeting
                                                </p>
                                                <p className="roadmap-description">
                                                    Share your amazing NFT project with us, i.e., website design, minting requirement
                                                </p>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-lg-end align-items-center roadmap-container container2 left">
                                    <div className="container12">
                                        <div className="original">
                                            <Fade left>
                                                <div className="roadmap-content content-left">
                                                    <p className="roadmap-title">
                                                        Website preview
                                                    </p>
                                                    <p className="roadmap-description">
                                                        Perfect your website before launching
                                                    </p>
                                                </div>
                                            </Fade>
                                        </div>
                                        <div className="original-reverse">
                                            <Fade right>
                                                <div className="roadmap-content content-left">
                                                    <p className="roadmap-title">
                                                        Website preview
                                                    </p>
                                                    <p className="roadmap-description">
                                                        Perfect your website before launching
                                                    </p>
                                                </div>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center roadmap-container container3 right">
                                    <div className="container13">
                                        <Fade right>
                                            <div className="roadmap-content content-right">
                                                <p className="roadmap-title">
                                                    Minting Rehearsal
                                                </p>
                                                <p className="roadmap-description">
                                                    For you to stay chill during the real mint
                                                </p>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-lg-end align-items-center roadmap-container container4 left">
                                    <div className="container14">
                                        <div className="original">
                                            <Fade left>
                                                <div className="roadmap-content content-left">
                                                    <p className="roadmap-title">
                                                        Official Launch
                                                    </p>
                                                    <p className="roadmap-description">
                                                        Time to let the world know the NFT project with the most potential
                                                    </p>
                                                </div>
                                            </Fade>
                                        </div>
                                        <div className="original-reverse">
                                            <Fade right>
                                                <div className="roadmap-content content-left">
                                                    <p className="roadmap-title">
                                                        Official Launch
                                                    </p>
                                                    <p className="roadmap-description">
                                                        Time to let the world know the NFT project with the most potential
                                                    </p>
                                                </div>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none pb-5">
                <Fade bottom>
                    <ApplyButton />
                </Fade>
            </div>
        </div>
    )
}