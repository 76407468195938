import React from 'react'
import Particles from 'react-tsparticles'
import { loadFull } from "tsparticles";
import { Engine } from 'tsparticles-engine';

const template = require('./json/particle.json')

export default function Particle() {
    const particlesInit = async (main: Engine) => {
        // console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };
    // const particlesLoaded = (container: any) => {
    //     console.log(container);
    // };
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            // loaded={particlesLoaded}
            options={template['particles']}
        />
    )
}